window.ImageRotator = (randomId) => {
	return {
		swiper: null,
		init() {
			setTimeout(() => {
				this.swiper = new Swiper('.swiper-container'+randomId, {
					slidesPerView: 1,
					loop: true,
					spaceBetween: 0,
					navigation: {
						nextEl: ".swiper-right" + randomId,
						prevEl: ".swiper-left" + randomId,
					},
					a11y: true
				})
			} , 0);
		}
	};
};
